<template>
    <div>
        <div style="position: relative;" class="quiz-box">

            <template v-for="(quiz, index) in q.value">
                <VueDragResize
                    :isActive="false"
                    :parentLimitation="true"
                    :isResizable="false"
                    :isDraggable="false"
                    :class="'quiz' + index">
                    <template v-if="q.type[index] === 'image'">
                        <img :src="quiz" :class="'quiz-content' + index" @click="playSound"/>
                    </template>
                    <template v-else-if="q.type[index] === 'audio'">
                        <audio :src="quiz" :class="'quiz-content' + index" @click="playSound"/>
                    </template>
                    <template v-else>
                        <div :class="'quiz-content' + index" @click="playSound"> {{ quiz }}</div>
                    </template>
                </VueDragResize>
            </template>

            <template v-for="(quiz, index) in q.value">
                <div :class="'quiz-area' + index">
                </div>
            </template>

            <template v-for="(answer, index) in a.value">
                <VueDragResize
                    @dragstop="onDrag(index, x, y)"
                    :w="0"
                    :h="0"
                    :isActive="true"
                    :parentLimitation="false"
                    :isResizable="false"
                    :isDraggable="true"
                    :class="'answer' + index"
                    >
                    <template v-if="a.type[index] === 'image'">
                        <img :src="answer" :class="'answer-content' + index" :id="a.key[index]" @click="playSound"/>
                    </template>
                    <template v-else-if="a.type[index] === 'audio'">
                        <audio :src="answer" :class="'answer-content' + index" :id="a.key[index]" @click="playSound"/>
                    </template>
                    <template v-else>
                        <div :class="'answer-content' + index" :id="a.key[index]" @click="playSound"> {{ answer }}</div>
                    </template>
                </VueDragResize>
            </template>

        </div>
        <input type="button" @click="chkAnswerAll"/>
    </div>

</template>

<script>
import Vue from 'vue'
import $ from 'jquery'
import _ from 'lodash'
import VueDragResize from 'vue-drag-resize'
import commonUtils from '@/commons/utils/common.utils'
import arrayUtils from '@/commons/utils/array.utils'

Vue.component('vue-drag-resize', VueDragResize)
Vue.prototype.commonUtils = commonUtils
Vue.prototype.arrayUtils = arrayUtils

export default {
    name: 'DNDTest',
    props: ['quizOptions', 'checkAnswerEachDrag', 'answerAllowRange', 'isShuffle', 'size', 'questAlign', 'q', 'a', 'idx', 'isPlayAudio'],
    components: {
        VueDragResize
    },
    data() {
        let quizOptions = {
            area: {
                size: this.size,
                checkAnswerEachDrag: this.checkAnswerEachDrag,
                answerAllowRange: this.answerAllowRange,
                isShuffle: this.isShuffle
            },
            q: this.q,
            a: this.a
        }
        // 정답 체크를 위한 2차원 배열 생성, 2차원 배열의 (i, v) 좌표로 체크
        let answerCheckArray = arrayUtils.createArr([quizOptions.a.value.length, quizOptions.a.value.length], null)
        // 정답 상태 1차원 배열
        let answerState = arrayUtils.createArr([quizOptions.q.answer.length], false)
        return {
            x: 0,
            y: 0,
            // 퀴즈 전반적 옵션 값을 가진 배열입니다.
            options: quizOptions,
            // 퀴즈 전체 영역 배열입니다.
            /**
             * quizBoxSize type : Array
             * quizBoxSize dimension : 1
             * quizBoxSize size : 2
             */
            quizBoxSize: Array,
            // 문제의 실제 정답 영역 left, top, right, bottom 배열입니다.
            /**
             * areaLTRB type : Array
             * areaLTRB dimension : 2
             * areaLTRB size of dimension 1 : size of quiz
             * areaLTRB size of dimension 2 : 4
             */
            areaLTRB: Array,
            // 현재 정답 요소의 위치 배열입니다.
            /**
             * answerCurrentPos type : Array
             * answerCurrentPos dimension : 2
             * answerCurrentPos size of dimension 1 : size of answer
             * answerCurrentPos size of dimension 2 : 2
             */
            answerCurrentPos: Array,
            // 정답 요소의 크기 배열입니다.
            /**
             * answerSize type : Array
             * answerSize dimension : 2
             * answerSize size of dimension 1 : size of answer
             * answerSize size of dimension 2 : 2
             */
            answerSize: Array,
            // 정답을 체크하기 위한 배열입니다.
            /**
             * answerCheckArray type : Array
             * answerCheckArray dimension : 2
             * answerCheckArray size of dimension 1 : size of quiz
             * answerCheckArray size of dimension 2 : size of answer
             */
            answerCheckArray: answerCheckArray,
            // 정답 상태 배열입니다.
            /**
             * answerState type : Array
             * answerState dimension : 1
             * answerState size : size of quiz
             */
            answerState: answerState,
            // 정답의 개수입니다.
            /**
             * completeAmount type : Number
             */
            completeAmount: 0,
            // 전부 정답인지 구분합니다.
            /**
             * completeAll type : Boolean
             */
            completeAll: false,
            res: ''
        }
    },
    created() {
        this.dataInit()
    },
    beforeMount() {

        const init = () => {
            this.$nextTick(() => {
                this.positionInit()
            })
        }
        $(window).resize(function () {
            init()
        })
        $(document).ready(function () {
            init()
        })
    },
    mounted() {

        const self = this

        const init = () => {
            this.$nextTick(() => {
                this.positionInit()
            })
        }

        $('img[class*="quiz"]')
            .on('load', function() { init() })

    },
    methods: {
        playSound() {
            this.isPlayAudio ? new Audio('http://localhost:8080/api/file/play/397').play() : {}
        },
        /**
         * 드래그 종료시 호출하는 함수입니다.
         * index 의 정답 요소의 현재 위치를 갱신합니다.
         *
         * index type : Number
         *
         * @param index
         */
        onDrag(index) {
            // 드래그 종료 시 [index, left, top] 으로 구성된 배열 생성
            let arr = [index,
                this.posInteg('.answer-content' + index, 'GET')[0] * 1 + this.posInteg('.answer' + index, 'GET')[0] * 1,
                this.posInteg('.answer-content' + index, 'GET')[1] * 1 + this.posInteg('.answer' + index, 'GET')[1] * 1]
            // 현재 위치 값 수정
            this.answerCurrentPos[arr[0]] = [arr[1], arr[2]]

            // 정답 요소가 전체 퀴즈 영역 밖에 위치하는지 체크
            if (!this.chkOutOfRange(arr)) {
                /**
                 *
                 *  OUT OF QUIZ BOX RANGE FUNCTION
                 *
                 */
            }

            // 매 드래그마다 정답 체크 옵션이 true 일 때 정답 체크
            if (this.options.area.checkAnswerEachDrag) {
                this.chkAnswer(arr)
                console.log('전체 정답 여부 : ' + this.completeAll, '\n정답 개수 : ' + this.completeAmount)
            }
        },

        /**
         * 정답 요소가 Box 를 이탈했는지 체크합니다.
         * _arr 는 index, index 의 정답요소의 left, index 의 정답요소의 top 으로 구성된 배열입니다
         *
         * _arr type : Array
         * _arr dimension : 1
         * _arr size : 3
         *
         * @param _arr
         * @returns {boolean}
         */
        chkOutOfRange(_arr) {
            if (_arr[1] < 0 || _arr[1] > this.quizBoxSize[0] - this.answerSize[_arr[0]][0] || _arr[2] < 0 || _arr[2] > this.quizBoxSize[1] - this.answerSize[_arr[0]][1]) {
                /**
                 *
                 * OUT OF QUIZ BOX RANGE FUNCTION
                 *
                 */
                return true
            }
            return false
        },

        /**
         * 모든 정답 요소의 정답을 체크합니다.
         */
        chkAnswerAll() {
            _.forEach(this.answerCurrentPos, (v, i) => {
                let arr = [i, v[0], v[1]]
                this.chkAnswer(arr)
            })
            console.log('전체 정답 여부 : ' + this.completeAll, '\n정답 개수 : ' + this.completeAmount)
            return this.completeAll
        },

        chkSubmitAnswer() {
            return (!arrayUtils.isEqualAllItemInArray(this.answerCheckArray[0]))
        },

        getAnswer() {
            return this.res
        },

        /**
         * _arr 에 대해 정답을 체크합니다.
         *
         * _arr type : Array
         * _arr dimension : 1
         * _arr size : 3
         *
         * @param _arr
         * @returns {boolean}
         */
        chkAnswer(_arr) {
            // 정답 인정 범위 배열
            const allow = [(this.answerSize[_arr[0]][0] * this.options.area.answerAllowRange / 100), (this.answerSize[_arr[0]][1] * this.options.area.answerAllowRange / 100)]

            // 각 퀴즈 영역에 문제가 포함되었는지 체크 후 2차원 정답 체크 배열에 삽입
            _.forEach(this.areaLTRB, (v, i) => {
                if (_arr[1] > v[0] - allow[0] && _arr[1] < v[2] - this.answerSize[_arr[0]][0] + allow[0] && _arr[2] > v[1] - allow[1] && _arr[2] < v[3] - this.answerSize[_arr[0]][1] + allow[1]) {
                    this.answerCheckArray[i][_arr[0]] = _arr[0]
                } else {
                    this.answerCheckArray[i][_arr[0]] = null
                }
            })

            _.forEach(this.answerCheckArray, (v, i) => {
                if (this.q.classification[i] === 'single') {

                    // 답안 분류가 single 일 때 제출한 답안의 개수가 1 이고 정답일 때 true
                    this.answerState[i] = (v[this.q.answer[i]] === this.q.answer[i] && arrayUtils.removeValueInArray(_.cloneDeep(v), null).length === 1)

                } else if (this.q.classification[i] === 'multi') {
                    // 답안 분류가 multi 일 때 제출한 답안의 개수가 일치하고 순서와 상관 없이 정답일 때 ture
                    this.answerState[i] = arrayUtils.isEqArray(arrayUtils.orderByDescArray(_.compact(_.cloneDeep(this.answerCheckArray[i]))), arrayUtils.orderByDescArray(_.compact(_.cloneDeep(this.q.answer[i]))))

                } else if (this.q.classification[i] === 'order') {

                    // 답안 분류가 order 일 때 제출한 답안의 개수가 일치하면 다음 프로세스 진행
                    if (arrayUtils.removeValueInArray(_.cloneDeep(v), null).length === this.q.answer[i].length) {

                        // 정답 요소의 left 기준으로 정답 요소를 정렬하여 순서가 맞는지 체크
                        let orderAnswerPos = arrayUtils.createArr([this.q.answer[i] instanceof Array ? this.q.answer[i].length : 1, 2])

                        let orderQuizState = new Array(this.q.answer[i].length)

                        _.forEach(arrayUtils.removeValueInArray(_.cloneDeep(v), null), (w, j) => {
                            orderAnswerPos[j] = [w, this.answerCurrentPos[w][0]]
                        })

                        _.forEach(arrayUtils.orderByDescArray(_.remove(_.flattenDeep(_.cloneDeep(orderAnswerPos)), function (v, i) {
                            return i % 2 === 1
                        })), (w, j) => {
                            orderQuizState[j] = orderAnswerPos[(_.flattenDeep(_.cloneDeep(orderAnswerPos)).indexOf(w) - 1) / 2][0]
                        })

                        this.answerState[i] = arrayUtils.isEqArray(orderQuizState, this.q.answer[i])

                    } else {

                        this.answerState[i] = false

                    }
                }
            })
            this.completeAll = arrayUtils.isEqualAllItemInArray(this.answerState) && this.answerState[0]
            this.completeAmount = arrayUtils.getValueAmountInArray(this.answerState, true)

            let tmp = ''
            _.forEach(this.answerCheckArray[0], (v, i) => {
                tmp = commonUtils.nullCheck(v) ? tmp : tmp + $('.answer-content' + v).attr('id') + ','
            })
            this.res = tmp.replace(/,\s*$/, "")

            console.log(this.res)

            return this.completeAll
        },

        /**
         * 데이터의 유효성 체크, 기본 값 설정, 정답 요소의 임의 배치등을 다루는 함수입니다.
         *
         * @returns {Promise<void>}
         */
        async dataInit() {
            // const imageExtension = Object.freeze(['jpeg', 'gif', 'bmp', 'png', 'jpg'])
            // const audioExtension = Object.freeze(['wav', 'mp3', 'ogg', 'msv'])
            //
            // const getType = (_string) => {
            //     if (imageExtension.includes(_string.slice(_string.lastIndexOf('.') + 1).toLowerCase())) {
            //         return 'image'
            //     } else if (audioExtension.includes(_string.slice(_string.lastIndexOf('.') + 1).toLowerCase())) {
            //         return 'audio'
            //     } else {
            //         return 'string'
            //     }
            // }

            /**
             *  --------------------
             *       기본값 설정
             *  --------------------
             */

            // 문제 전체 영역이 null 일 때 ['100%', '100%'] 로 설정
            if (commonUtils.nullCheck(this.options.area.size)) this.options.area.size = ['100%', '100%']
            // 드래그마다 정답 체크 옵션이 null 일 때 Default true 로 설정
            if (commonUtils.nullCheck(this.options.area.checkAnswerEachDrag)) this.options.area.checkAnswerEachDrag = true
            // 정답 임의 재배열 옵션이 null 일 때 Default ture 로 설정
            if (commonUtils.nullCheck(this.options.area.isShuffle)) this.options.area.isShuffle = true
            // 정답의 크기 기준 문제 영역 포함 인정 범위가 null 일 때 Default 100 으로 설정, 허용된 범위 (0~100)이 아닐 때 0 또는 100으로 설정
            if (commonUtils.nullCheck(this.options.area.answerAllowRange) || this.options.area.answerAllowRange > 100) {
                this.options.area.answerAllowRange = 100
            } else if (commonUtils.nullCheck(this.options.area.answerAllowRange < 0)) this.options.area.answerAllowRange = 0

            /**
             *  --------------------
             *       유효성 검사
             *  --------------------
             */

            // 정렬 방식이 자동이 아닐 때 주어진 위치 값의 개수가 문제의 개수와 일치하지 않을 때 에러를 표출합니다.
            if (this.a.align !== 'auto' && this.a.pos.length !== this.a.value.length) throw new Error('answer "position" is not available array size')
            // 정답의 크기의 개수가 1이 아니고 정답의 크기 개수가 정답의 개수와 일치하지 않을 때 에러를 표출합니다.
            if (this.a.size.length !== 1 && this.a.size.length !== this.a.value.length) throw new Error('answer "size" is not available array size')
            // 문제의 크기의 개수가 1이 아니고 문제의 크기 개수가 문제의 개수와 일치하지 않을 때 에러를 표출합니다.
            if (this.q.size.length !== 1 && this.q.size.length !== this.q.value.length) throw new Error('quiz "size" is not available array size')
            // 퀴즈 유형이 단일 정답 문제 일 때 배열 형태의 답안을 매칭하고 있으면 에러를 표출합니다.
            _.forEach(this.q.classification, (v, i) => {
                if (v === 'single' && this.q.answer[i] instanceof Array) throw new Error('single type is available only single number')
            })
            // 답안의 요소가 숫자가 아니면 에러를 표출합니다.
            _.forEach(_.flattenDeep(_.cloneDeep(this.q.answer), true), (value) => {
                if (isNaN(value * 1)) throw new Error('answer is only available number (index of answer item)')
            })

            /**
             *  --------------------
             *  편의로 지정된 값 초기화
             *  --------------------
             */

            // 정렬 방식이 자동일 때 주어진 위치 값 중 첫 번째 높이 값만 사용 되고 정답간 간격을 자동으로 설정합니다.
            if (this.a.align === 'auto') {
                if (this.a.pos instanceof Array) this.a.pos = _.flattenDeep(this.a.pos)[_.flattenDeep(this.a.pos).length - 1]
            }
            // 정답의 크기의 개수가 한 개 일 때 나머지 크기를 첫 번째 크기로 설정합니다.
            if (this.a.size.length === 1 && this.a.value.length !== 1) {
                for (let i = 1; i < this.a.value.length; i++) {
                    this.a.size[i] = this.a.size[0]
                }
            }
            // 문제의 크기의 개수가 한 개 일 때 나머지 크기를 첫 번째 크기로 설정합니다.
            if (this.q.size.length === 1 && this.q.value.length !== 1) {
                for (let i = 1; i < this.q.value.length; i++) {
                    this.q.size[i] = this.q.size[0]
                }
            }
            // // 정답의 타입을 문제의 확장자에서 가져옵니다.
            // this.a.type = Array.from({length: this.a.value.length}, () => '')
            // for (let i = 0; i < this.a.value.length; i++) {
            //     this.a.type[i] = getType(this.a.value[i])
            // }
            // // 문제의 타입을 정답의 확장자에서 가져옵니다.
            // this.q.type = Array.from({length: this.q.value.length}, () => '')
            // for (let i = 0; i < this.q.value.length; i++) {
            //     this.q.type[i] = getType(this.q.value[i])
            // }
            // 실제 문제 영역의 값이 'auto' 일 때 Left, Top, Right, Bottom 설정
            _.forEach(this.q.area, (v, i) => {
                if (v[0] === 'auto') this.q.area[i] = ['0%', '0%', '100%', '100%']
            })

            console.log(Object.freeze(this.a.key))

            if (this.options.area.isShuffle) {

                // 정답 사이즈의 중복 없는 난수 배열 생성합니다.
                let randArr = arrayUtils.createRandNumArr(this.a.value.length, false)

                let randValue = new Array(0)
                let randAnswer = new Array(0)
                let randKey = new Array(0)

                // 생성된 난수 배열에 문제 및 정답을 매칭합니다.
                _.forEach(randArr, (v, i) => {
                    randValue[i] = this.a.value[v]
                    randKey[i] = this.a.key[v]
                    randAnswer.push(randValue.indexOf(this.a.value[_.flattenDeep(this.q.answer)[i]]))
                })
                _.forEach(_.flattenDeep(_.cloneDeep(this.q.answer)), (v, i) => {
                    randAnswer[i] = randValue.indexOf(this.a.value[v])
                })

                // 다중 정답 문제 및 다중 순서 정렬 정답 문제의 2차원 배열에 대응하여 매칭한 답안 배열을 재정렬합니다.
                this.q.answer = arrayUtils.reshapeArr(this.q.answer, randAnswer)
                this.a.key = randKey

                this.a.value = randValue
            }

            console.log(this.a.key)

        },

        /**
         * 위치 및 크기를 설정합니다.
         *
         * @returns {Promise<void>}
         */
        async positionInit() {

            let self = this

            self.$nextTick(() => {

                // let boxSize = self.sizeInteg(self.styleInteg('.quiz-box', 0, 'ELEMENT', '', [-1, -1]), 'GET')
                let boxSize = [window.innerWidth, window.innerHeight]

                let quizSize = arrayUtils.createArr([self.q.answer.length, 2], null)
                let quizPos = arrayUtils.createArr([self.q.answer.length, 2], null)

                let areaSize = arrayUtils.createArr([self.q.answer.length, 2], null)
                let areaPos = arrayUtils.createArr([self.q.answer.length, 2], null)
                let areaLTRB = arrayUtils.createArr([self.q.answer.length, 4], null)

                let answerSize = arrayUtils.createArr([self.a.value.length, 2], null)
                let answerPos = arrayUtils.createArr([self.a.value.length, 2], null)

                // 전체 영역의 크기를 지정합니다.
                _.forEach(self.options.area.size, (v, i) => {
                    boxSize[i] = isNaN(v * 1) ? boxSize[i] * (v.replace('%', '') * 1) / 100 : v
                })
                boxSize[0] = boxSize[0] > 625 ? 625 : boxSize[0]
                boxSize[1] = boxSize[1] > 200 ? 200 : boxSize[1]
                self.sizeInteg('.quiz-box', 'SET', boxSize)

                // 문제 요소의 크기를 지정합니다.
                _.forEach(self.q.size, (v, i) => {
                    if (v.length > 1) {
                        _.forEach(v, (w, j) => {
                            quizSize[i][j] = isNaN(w * 1) ? boxSize[j] * (w.replace('%', '') * 1) / 100 : w
                        })
                    } else {
                        quizSize[i][0] = isNaN(v * 1) ? boxSize[0] * (v[0].replace('%', '') * 1) / 100 : v
                        quizSize[i][1] = 'auto'
                    }
                    self.sizeInteg('.quiz-content' + i, 'SET', quizSize[i], [-1, -1], true)
                })

                // 문제 요소의 위치를 지정합니다.
                _.forEach(self.q.pos, (v, i) => {
                    if (self.q.value.length === 1 && self.q.align === 'auto') {
                        quizPos[i][0] = (boxSize[0] - quizSize[i][0]) / 2
                        quizPos[i][1] = isNaN(v[1] * 1) ? boxSize[1] * (v[1].replace('%', '') * 1) / 100 : v[1]
                    } else {
                        _.forEach(v, (w, j) => {
                            quizPos[i][j] = isNaN(w * 1) ? boxSize[j] * (w.replace('%', '') * 1) / 100 : w
                        })
                    }
                    self.posInteg('.quiz-content' + i, 'SET', quizPos[i], [-1, -1], false)
                })

                // 실제 문제 영역의 위치 및 크기를 지정합니다.
                _.forEach(self.q.area, (v, i) => {
                    // 실제 문제 영역의 css position 속성을 absolute 로 설정합니다.
                    self.styleInteg('.quiz-area' + i, 0, 'position', 'absolute')


                    /**
                     * -----------------------------
                     * 테스트용 실제 정답 영역 가시화 코드
                     * -----------------------------
                     */
                    // self.styleInteg(['.quiz-area' + i, '.quiz-area' + i], [0, 0], ['backgroundColor', 'opacity'], ['#FF0000', '0.3'])


                    areaPos[i][0] = isNaN(v[0] * 1) ?
                        self.posInteg('.quiz' + i, 'GET')[0] * 1 + ((self.sizeInteg('.quiz' + i, 'GET')[0] * 1) * (v[0].replace('%', '') * 1) / 100) :
                        self.posInteg('.quiz' + i, 'GET')[0] * 1 + v[0] * 1

                    areaPos[i][1] = isNaN(v[1] * 1) ?
                        self.posInteg('.quiz' + i, 'GET')[1] * 1 + ((self.sizeInteg('.quiz' + i, 'GET')[1] * 1) * (v[1].replace('%', '') * 1) / 100) :
                        self.posInteg('.quiz' + i, 'GET')[1] * 1 + v[1] * 1

                    areaSize[i][0] = isNaN(v[2] * 1) ?
                        Math.abs(areaPos[i][0] - (self.posInteg('.quiz' + i, 'GET')[0] * 1 + ((self.sizeInteg('.quiz' + i, 'GET')[0] * 1) * (v[2].replace('%', '') * 1) / 100))) :
                        Math.abs(areaPos[i][0] - (self.posInteg('.quiz' + i, 'GET')[0] * 1 + v[2] * 1))

                    areaSize[i][1] = isNaN(v[3] * 1) ?
                        Math.abs(areaPos[i][1] - (self.posInteg('.quiz' + i, 'GET')[1] * 1 + ((self.sizeInteg('.quiz' + i, 'GET')[1] * 1) * (v[3].replace('%', '') * 1) / 100))) :
                        Math.abs(areaPos[i][1] - (self.posInteg('.quiz' + i, 'GET')[1] * 1 + v[3] * 1))

                    // 실제 문제 영역의 Left, Top, Right, Bottom 좌표를 구합니다.
                    areaLTRB[i][0] = areaPos[i][0]
                    areaLTRB[i][1] = areaPos[i][1]
                    areaLTRB[i][2] = areaPos[i][0] + areaSize[i][0]
                    areaLTRB[i][3] = areaPos[i][1] + areaSize[i][1]

                    self.posInteg('.quiz-area' + i, 'SET', areaPos[i])
                    self.sizeInteg('.quiz-area' + i, 'SET', areaSize[i])
                })

                // 정답 요소의 크기를 설정합니다.
                _.forEach(self.a.size, (v, i) => {
                    _.forEach(v, (w, j) => {
                        answerSize[i][j] = isNaN(w * 1) ? boxSize[j] * (w.replace('%', '') * 1) / 100 : w
                    })
                    self.sizeInteg('.answer-content' + i, 'SET', answerSize[i])
                })

                // 정답 요소의 위치를 설정합니다.
                _.forEach(self.a.value, (v, i) => {
                    if (self.a.align === 'auto') {

                        /**
                         * 정렬 방식이 auto 일 때
                         *
                         * 전체 영역의 가로 길이 값을 w,
                         * k 번째 요소의 가로 길이 값을 w_k,
                         * k 번째 요소의 left 값을 l_k,
                         * 요소의 개수를 e 라고 하면
                         *
                         * n 번째 요소의 left 값 l_n = (n + 1)(w - Σ(k = 0 to k = e + 1)(w_k)) + Σ(k = 0 to k = n - 1)(w_k)
                         * 단, n 은 0 이상 정수이며 n = 0 : Σ(k = 0 to k = n - 1)(w_k) = 0
                         */

                        answerPos[i][0] = (((boxSize[0] - _.sum(_.remove(_.flattenDeep(_.cloneDeep(answerSize)), function (w, j) {
                                return j % 2 === 0
                            }))) / (self.a.value.length + 1)) * (i + 1)) +
                            _.sum(_.remove(_.remove(_.flattenDeep(_.cloneDeep(answerSize)), function (w, j) {
                                return j % 2 === 0
                            }), function (x, k) {
                                return k < i
                            }))
                        answerPos[i][1] = isNaN(self.a.pos * 1) ? boxSize[1] * (self.a.pos.replace('%', '') * 1) / 100 : self.a.pos
                    } else {
                        _.forEach(v, (w, j) => {
                            answerPos[i][j] = isNaN(w * 1) ? boxSize[j] * (w.replace('%', '') * 1) / 100 : w
                        })
                    }
                    console.log(boxSize, answerPos)

                    self.styleInteg('.answer-content' + i, 0, 'position', 'absolute')
                    self.posInteg('.answer-content' + i, 'SET', answerPos[i], [0, 0], true)
                })

                self.quizBoxSize = boxSize
                self.quizPos = quizPos
                self.quizSize = quizSize
                self.areaLTRB = areaLTRB
                self.answerPos = answerPos
                self.answerCurrentPos = answerPos
                self.answerSize = answerSize

            })
        },

        /**
         * 스타일 통합 함수
         *
         * @param _el
         * HTML Element 또는 선택자 string 파라미터입니다.
         * @param _eq
         * 선택된 HTML Element 중 _eq 번째 요소를 사용하는 파라미터입니다.
         * @param _cssName
         * 수정할 css 속성 명 파라미터입니다. 인자로 'ELEMENT' 를 받으면 HTML Element 를 리턴합니다.
         * @param _cssValue
         * 수정할 css 속성 값 파라미터입니다. 인지로 'GET' 을 받으면 인자로 받은 css 속성 명의 값을 리턴합니다.
         * @param _childNode
         * 자식, 자손, 부모노드 선택 Number Array 입니다. 배열의 값이 0보다 작을 때 부모 노드를 선택합니다.
         * @param _isApplyChildNode
         * _childNode 로 탐색하며 지나간 모든 인자의 css 를 변경할지 결장하는 인자입니다. Boolean
         * @returns {*}
         */
        styleInteg(_el, _eq, _cssName, _cssValue, _childNode, _isApplyChildNode) {
            if (_el instanceof Array) {
                if (arrayUtils.isEqArrayLength(false, _el, _eq, _cssName, _cssValue, _childNode)) {
                    // 주어진 HTML 요소의 인자가 배열이고 모든 인자의 배열의 길이가 같을 때 각 배열의 같은 인덱스 번호로 재귀함수를 호출합니다.
                    _.forEach(_el, (v, i) => {
                        this.styleInteg(_el[i], _eq[i], _cssName[i], _cssValue[i], commonUtils.nullCheck(_childNode) ? null : _childNode[i], commonUtils.nullCheck(_isApplyChildNode) ? null : _isApplyChildNode[i])
                    })
                } else if (!arrayUtils.isEqArrayLength(false, _el, _eq, _cssName, _cssValue, _childNode)) {
                    // 주어진 HTML 요소의 인자가 배열이고 모든 인자의 배열의 길이가 같지 않을 때 HTML 요소의 배열은 인덱스 번호로, 이외 인자의 배열은 첫 번째 인덱스 번호로 재귀함수를 호출합니다.
                    _.forEach(_el, (v, i) => {
                        this.styleInteg(_el[i], _.first(_eq), _.first(_cssName), _.first(_cssValue), commonUtils.nullCheck(_childNode) ? null : _.first(_childNode), commonUtils.nullCheck(_isApplyChildNode) ? null : _.first(_isApplyChildNode))
                    })
                }
                return
            }
            // 주어진 HTML 요소의 인자의 프로토타입 속성이 HTMLElement 가 아닐 때 JQuery 선택자로 사용합니다.
            if (!(_el instanceof HTMLElement)) _el = $(_el)
            _el = _el.eq(commonUtils.nullCheck(_eq) ? 0 : _eq)
            // 숫자로 이루어진 배열로 자식, 자손 노드를 찾아갑니다. 자식, 자손 노드를 찾는 배열의 값이 음수일 때 부모 노드를 찾아갑니다.
            if (!commonUtils.nullCheck(_childNode)) {
                _.forEach(_childNode, (v) => {
                    if (_isApplyChildNode) _el.css(_cssName, _cssValue)
                    if (v < 0) {
                        _el = _el.parent()
                    } else {
                        _el.children().eq(v)
                    }
                })
            }
            // css 속성 명이 'ELEMENT' 일 때 HTML Element 를 반환합니다. css 속성 값이 'GET' 일 때 속성의 값을 반환합니다.
            if (_cssName === 'ELEMENT') {
                return _el
            } else if (_cssValue === 'GET') {
                return _el.css(_cssName)
            } else {
                _el.css(_cssName, _cssValue)
            }
        },

        /**
         * 사이즈 통합 함수
         *
         * @param _el
         * HTML Element 또는 선택자 string 파라미터입니다.
         * @param _METHOD
         * 'GET' 을 받으면 선택된 HTML Element 의 가로, 세로 값 size : 2 의 Number Array 를 리턴합니다.
         * 'SET' 을 받으면 선택된 HTML Element 의 가로, 세로 값을 아래 받은 _sizeArray size : 2 의 Number Array 로 설정합니다.
         * @param _sizeArray
         * size : 2 의 Number Array
         * @param _childNode
         * 자식, 자손, 부모노드 선택 Number Array 입니다. 배열의 값이 0보다 작을 때 부모 노드를 선택합니다.
         * @param _isApplyChildNode
         * _childNode 로 탐색하며 지나간 모든 인자의 가로, 세로를 변경할지 결장하는 인자입니다. Boolean
         * @returns {*[]}
         */
        sizeInteg(_el, _METHOD, _sizeArray, _childNode, _isApplyChildNode) {
            if (!(_el instanceof HTMLElement)) _el = $(_el)
            if (_METHOD === 'GET') {
                return [_el.width(), _el.height()]
            } else if (_METHOD === 'SET') this.styleInteg([_el, _el], [0, 0], ['width', 'height'], _sizeArray, [_childNode, _childNode], [_isApplyChildNode, _isApplyChildNode])
        },

        /**
         * 위치 통합 함수
         *
         * @param _el
         * HTML Element 또는 선택자 string 파라미터입니다.
         * @param _METHOD
         * 'GET' 을 받으면 선택된 HTML Element 의 left, top 값 size : 2 의 Number Array 를 리턴합니다.
         * 'SET' 을 받으면 선택된 HTML Element 의 left, top 값을 아래 받은 _sizeArray size : 2 의 Number Array 로 설정합니다.
         * @param _sizeArray
         * size : 2 의 Number Array
         * @param _childNode
         * 자식, 자손, 부모노드 선택 Number Array 입니다. 배열의 값이 0보다 작을 때 부모 노드를 선택합니다.
         * @param _isApplyChildNode
         * _childNode 로 탐색하며 지나간 모든 인자의 left, top 을 변경할지 결장하는 인자입니다. Boolean
         * @returns {*[]}
         */
        posInteg(_el, _METHOD, _sizeArray, _childNode, _isApplyChildNode) {
            if (!(_el instanceof HTMLElement)) _el = $(_el)
            if (_METHOD === 'GET') {
                return [_el.css('left').replace('px', ''), _el.css('top').replace('px', '')]
            } else if (_METHOD === 'SET') this.styleInteg([_el, _el], [0, 0], ['left', 'top'], _sizeArray, [_childNode, _childNode], [_isApplyChildNode, _isApplyChildNode])
        },
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Mochiy+Pop+P+One&display=swap');

.quiz-box {
    -ms-user-select: none;
    -moz-user-select: -moz-none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    user-select: none;
    font-size: 18px;
    color: black;
    font-family: 'Mochiy Pop P One', sans-serif;
}
</style>
